@font-face {
  font-family: "open sans";
  src: url(/FONTS/OpenSans.ttf) format("truetype");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "playfair display sc";
  src: url(/FONTS/PlayfairDisplaySC.ttf) format("truetype");
  font-weight: 400;
  font-style: normal;
}

body {
  margin: 0;
  font-family: "open sans";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* {
  margin: 0;
  padding: 0;
}
