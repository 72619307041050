.serif-font {
  font-family: "playfair display sc";
}

.sans-serif-font {
  font-family: "open sans";
}

.text-accent-grey {
  color: #ebebeb;
}

.text-grey {
  color: #5f5f5f;
}

h1 {
  display: block;
  font-family: "Playfair Display SC";
  font-size: 2em;
  margin-bottom: 0.5em;
  font-weight: bold;
}

h2 {
  display: block;
  font-family: "Playfair Display SC";
  font-size: 1.5em;
  margin-bottom: 0.5em;
  margin-top: 1.5em;
  font-weight: bold;
}

h3 {
  display: block;
  font-family: "Playfair Display SC";
  font-size: 1.2em;
  margin-bottom: 0.5em;
  margin-top: 1.2em;
  font-weight: bold;
}

p {
  margin-bottom: 1em;
  line-height: 1.7;
  font-size: 1.1em;
}

ul,
ol {
  margin-top: 0.5em;
  margin-bottom: 1em;
}

li {
  margin-bottom: 0.5em;
  margin-left: 1em;
}

blockquote {
  background-color: #f5f5f5;
  border-radius: 0 5px 5px 0;
  margin: 1em;
  padding-left: 0.5em;
  padding-right: 0.5em;
  border-left: grey;
  border-left-style: solid;
}

.hamburger {
  outline: none !important;
  padding: 9px 0 0 0 !important;
  margin-right: 1.5em;
}

.show-nav {
  display: block !important;
}

.main-body a {
  text-decoration: underline;
}

.main-body a:hover,
.main-body a:focus {
  color: #5f5f5f;
  box-shadow: 1px 1px 2px;
}

.main-body img,
.main-body video {
  box-shadow: 1px 1px 4px 2px;
}

.resources h4 {
  color: #5f5f5f;
}

@media only screen and (max-width: 767px) {
  .sm-border {
    border-top: 1px dotted grey;
    padding-top: 1em;
  }
}

.product-hunt-badge {
  padding-right: 1em;
  margin-bottom: 1em;
  max-width: 200px;
}

@keyframes waveAnimation {
  0% {
    transform: rotate(0deg);
  }
  35% {
    transform: rotate(10deg);
  }
  45% {
    transform: rotate(-10deg);
  }
  55% {
    transform: rotate(0deg);
  }
  60% {
    transform: rotate(10deg);
  }
  70% {
    transform: rotate(-10deg);
  }
  80% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

.wave-hi {
  display: inline-block;
  animation-iteration-count: 1;
  animation: waveAnimation 3s forwards;
}
